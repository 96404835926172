import React from 'react'
import { Container, Flex, Link as A, Text } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  FaqHeader,
  CareerRow,
  contentContainer,
  content,
  CareerSectionLead,
  PrivacyBackground
} from 'components/Content'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

A.link = A.withComponent(Link)

const title = 'Terms & Conditions - Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/terms/"
    img="/cards/workshops.png"
  >
    <Nav />
    <Social />
    <PrivacyBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[3, 4]} mb={[4, 5]} mx="auto">
            TERMS OF SERVICE
          </Headline>
        </Container>
      </Flex>
    </PrivacyBackground>
    <Container {...contentContainer}>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>LAST REVISION: 2020</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
        WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY
        ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </CareerSectionLead>
      <CareerSectionLead>
        This Terms of Service Agreement (the “Agreement”) governs your use of
        this website, https://www.Coding Sastra.com (the “Website”), Coding
        Sastra (“Business Name”) offer of products for purchase on this Website,
        or your purchase of products available on this Website. This Agreement
        includes, and incorporates by this reference, the policies and
        guidelines referenced below. Coding Sastra reserves the right to change
        or revise the terms and conditions of this Agreement at any time by
        posting any changes or a revised Agreement on this Website. Coding
        Sastra will alert you that changes or revisions have been made by
        indicating on the top of this Agreement the date it was last revised.
        The changed or revised Agreement will be effective immediately after it
        is posted on this Website. Your use of the Website following the posting
        any such changes or of a revised Agreement will constitute your
        acceptance of any such changes or revisions. Coding Sastra encourages
        you to review this Agreement whenever you visit the Website to make sure
        that you understand the terms and conditions governing use of the
        Website. This Agreement does not alter in any way the terms or
        conditions of any other written agreement you may have with Coding
        Sastra for other products or services. If you do not agree to this
        Agreement (including any referenced policies or guidelines), please
        immediately terminate your use of the Website. If you would like to
        print this Agreement, please click the print button on your browser
        toolbar.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>I. PRODUCTS</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Terms of Offer. This Website offers for sale certain products (the
        “Products”). By placing an order for Products through this Website, you
        agree to the terms set forth in this Agreement.
      </CareerSectionLead>
      <CareerSectionLead>
        Customer Solicitation: Unless you notify our third party call center
        reps or direct Coding Sastra sales reps, while they are calling you, of
        your desire to opt out from further direct company communications and
        solicitations, you are agreeing to continue to receive further emails
        and call solicitations Coding Sastra and its designated in house or
        third party call team(s).
      </CareerSectionLead>
      <CareerSectionLead>
        Opt Out Procedure: We provide 3 easy ways to opt out of from future
        solicitations. You may use the opt out link found in any email
        solicitation that you may receive. You may also choose to opt out, via
        sending your email address to: hi@Coding Sastra.com You may send a
        written remove request to Coding Sastra,
        <br /> D.No: 49-52-11, Ground Floor Opp: HP Petrol Bunk,
        <br /> Sankarmatam Road Santhipuram Jn,
        <br />
        Visakhapatnam-530016.
      </CareerSectionLead>
      <CareerSectionLead>
        Proprietary Rights. Coding Sastra has proprietary rights and trade
        secrets in the Products. You may not copy, reproduce, resell or
        redistribute any Product manufactured and/or distributed by Coding
        Sastra. Coding Sastra also has rights to all trademarks and trade dress
        and specific layouts of this webpage, including calls to action, text
        placement, images and other information.
      </CareerSectionLead>
      <CareerSectionLead>
        Sales Tax. If you purchase any Products, you will be responsible for
        paying any applicable sales tax.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>II. WEBSITE</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Content; Intellectual Property; Third Party Links. In addition to making
        Products available, this Website also offers information and marketing
        materials. This Website also offers information, both directly and
        through indirect links to third-party websites, about nutritional and
        dietary supplements. Coding Sastra does not always create the
        information offered on this Website; instead the information is often
        gathered from other sources. To the extent that Coding Sastra does
        create the content on this Website, such content is protected by
        intellectual property laws of the India, foreign nations, and
        international bodies. Unauthorized use of the material may violate
        copyright, trademark, and/or other laws. You acknowledge that your use
        of the content on this Website is for personal, noncommercial use. Any
        links to third-party websites are provided solely as a convenience to
        you. Coding Sastra does not endorse the contents on any such third-party
        websites. Coding Sastra is not responsible for the content of or any
        damage that may result from your access to or reliance on these
        third-party websites. If you link to third-party websites, you do so at
        your own risk.
      </CareerSectionLead>
      <CareerSectionLead>
        Use of Website; Coding Sastra is not responsible for any damages
        resulting from use of this website by anyone. You will not use the
        Website for illegal purposes. You will (1) abide by all applicable
        local, state, national, and international laws and regulations in your
        use of the Website (including laws regarding intellectual property), (2)
        not interfere with or disrupt the use and enjoyment of the Website by
        other users, (3) not resell material on the Website, (4) not engage,
        directly or indirectly, in transmission of “spam”, chain letters, junk
        mail or any other type of unsolicited communication, and (5) not defame,
        harass, abuse, or disrupt other users of the Website
      </CareerSectionLead>
      <CareerSectionLead>
        License. By using this Website, you are granted a limited,
        non-exclusive, non-transferable right to use the content and materials
        on the Website in connection with your normal, noncommercial, use of the
        Website. You may not copy, reproduce, transmit, distribute, or create
        derivative works of such content or information without express written
        authorization from Coding Sastra or the applicable third party (if third
        party content is at issue).
      </CareerSectionLead>
      <CareerSectionLead>
        Posting. By posting, storing, or transmitting any content on the
        Website, you hereby grant Coding Sastra a perpetual, worldwide,
        non-exclusive, royalty-free, assignable, right and license to use, copy,
        display, perform, create derivative works from, distribute, have
        distributed, transmit and assign such content in any form, in all media
        now known or hereinafter created, anywhere in the world. Coding Sastra
        does not have the ability to control the nature of the user-generated
        content offered through the Website. You are solely responsible for your
        interactions with other users of the Website and any content you post.
        Coding Sastra is not liable for any damage or harm resulting from any
        posts by or interactions between users. Coding Sastra reserves the
        right, but has no obligation, to monitor interactions between and among
        users of the Website and to remove any content Coding Sastra.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>III. DISCLAIMER OF WARRANTIES</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE RISK.THE
        WEBSITE AND PRODUCTS ARE OFFERED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        Coding Sastra EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
        WITH RESPECT TO THE PRODUCTS OR WEBSITE CONTENT, OR ANY RELIANCE UPON OR
        USE OF THE WEBSITE CONTENT OR PRODUCTS. (“PRODUCTS”) INCLUDE TRIAL
        PRODUCTS.)
      </CareerSectionLead>
      <Text style={{ fontSize: '20px' }} mb={2}>
        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, Coding Sastra MAKES NO
        WARRANTY:
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS ACCURATE,RELIABLE,
        COMPLETE, OR TIMELY.
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        THAT THE LINKS TO THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS
        ACCURATE, RELIABLE, COMPLETE, OR TIMELY.
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        THIS WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PRODUCTS OR
        THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE WEBSITE.
      </Text>
      <Text style={{ fontSize: '20px' }} mb={2}>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO
        SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
      </Text>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>IV. LIMITATION OF LIABILITY</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        CODING SASTRA ENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN LAW, IN
        EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE CONTENT AND PRODUCTS
        AND/OR FOR ANY BREACH OF THIS AGREEMENT IS SOLELY LIMITED TO THE AMOUNT
        YOU PAID, LESS SHIPPING AND HANDLING, FOR PRODUCTS PURCHASED VIA THE
        WEBSITE.
      </CareerSectionLead>
      <CareerSectionLead>
        CODING SASTRA WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL,SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS
        AGREEMENT OR THE PRODUCTS IN ANY MANNER, INCLUDING LIABILITIES RESULTING
        FROM (1) THE USE OR THE INABILITY TO USE THE WEBSITE CONTENT OR
        PRODUCTS; (2) THE COST OF PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3)
        ANY PRODUCTS PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH
        THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE.
      </CareerSectionLead>
      <CareerSectionLead>
        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
        FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE LIMITATIONS
        MAY NOT APPLY TO YOU.
      </CareerSectionLead>
      <CareerRow my={5} {...content} fontSize={4} color="success">
        <Text mb={0}>
          <strong>IV. LIMITATION OF LIABILITY</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        You will release, indemnify, defend and hold harmless Coding Sastra, and
        any of its contractors, agents, employees, officers, directors,
        shareholders, affiliates and assigns from all liabilities, claims,
        damages, costs and expenses, including reasonable attorneys’ fees and
        expenses, of third parties relating to or arising out of <br />
        (1) this Agreement or the breach of your warranties, representations and
        obligations under this Agreement; <br />
        (2) the Website content or your use of the Website content; <br />
        (3) the Products or your use of the Products (including Trial Products);{' '}
        <br />
        (4) any intellectual property or other proprietary right of any person
        or entity; <br />
        (5) your violation of any provision of this Agreement; or <br />
        (6) any information or data you supplied to Coding Sastra. When Coding
        Sastra is threatened with suit or sued by a third party, Coding Sastra
        may seek written assurances from you concerning your promise to
        indemnify Coding Sastra; your failure to provide such assurances may be
        considered by Coding Sastra to be a material breach of this Agreement.{' '}
        <br />
        Coding Sastra will have the right to participate in any defense by you
        of a third-party claim related to your use of any of the Website content
        or Products, with counsel of Coding Sastra choice at its expense. Coding
        Sastra will reasonably cooperate in any defense by you of a third-party
        claim at your request and expense. You will have sole responsibility to
        defend Coding Sastra against any claim, but you must receive Coding
        Sastra prior written consent regarding any related settlement. The terms
        of this provision will survive any termination or cancellation of this
        Agreement or your use of the Website or Products.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>VI. PRIVACY</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Coding Sastra believes strongly in protecting user privacy and providing
        you with notice of MuscleUP Nutrition ‘s use of data. Please refer to
        Coding Sastra privacy policy, incorporated by reference herein, that is
        posted on the Website
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>VI. AGREEMENT TO BE BOUND</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        By using this Website or ordering Products, you acknowledge that you
        have read and agree to be bound by this Agreement and all terms and
        conditions on this Website.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="success">
          <strong>VIII. GENERAL</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Force Majeure. Coding Sastra will not be deemed in default hereunder or
        held responsible for any cessation, interruption or delay in the
        performance of its obligations hereunder due to earthquake, flood, fire,
        storm, natural disaster, act of God, war, terrorism, armed conflict,
        labor strike, lockout, or boycott.
        <br />
        <br /> Cessation of Operation. Coding Sastra may at any time, in its
        sole discretion and without advance notice to you, cease operation of
        the Website and distribution of the Products.
        <br />
        <br /> Entire Agreement. This Agreement comprises the entire agreement
        between you and Coding Sastra and supersedes any prior agreements
        pertaining to the subject matter contained herein.
        <br />
        <br /> Effect of Waiver. The failure of Coding Sastra to exercise or
        enforce any right or provision of this Agreement will not constitute a
        waiver of such right or provision. If any provision of this Agreement is
        found by a court of competent jurisdiction to be invalid, the parties
        nevertheless agree that the court should endeavor to give effect to the
        parties’ intentions as reflected in the provision, and the other
        provisions of this Agreement remain in full force and effect.
        <br /> <br />
        Governing Law; Jurisdiction. This Website originates from the
        [Visakhapatanam, Andhra pradesh]. This Agreement will be governed by the
        laws of the State of [Law State Name] without regard to its conflict of
        law principles to the contrary. Neither you nor Coding Sastra will
        commence or prosecute any suit, proceeding or claim to enforce the
        provisions of this Agreement, to recover damages for breach of or
        default of this Agreement, or otherwise arising under or by reason of
        this Agreement, other than in courts located in State of Andhra Pradesh.
        By using this Website or ordering Products, you consent to the
        jurisdiction and venue of such courts in connection with any action,
        suit, proceeding or claim arising under or by reason of this Agreement.
        You hereby waive any right to trial by jury arising out of this
        Agreement and any related documents.
        <br /> <br />
        Statute of Limitation. You agree that regardless of any statute or law
        to the contrary, any claim or cause of action arising out of or related
        to use of the Website or Products or this Agreement must be filed within
        one (1) year after such claim or cause of action arose or be forever
        barred.
        <br />
        <br /> Waiver of Class Action Rights. BY ENTERING INTO THIS AGREEMENT,
        YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH
        THOSE OF OTHER IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL
        DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTION WITH THIS
        AGREEMENT MUST BE ASSERTED INDIVIDUALLY.
        <br />
        <br /> Termination. Coding Sastra reserves the right to terminate your
        access to the Website if it reasonably believes, in its sole discretion,
        that you have breached any of the terms and conditions of this
        Agreement. Following termination, you will not be permitted to use the
        Website and Coding Sastra may, in its sole discretion and without
        advance notice to you, cancel any outstanding orders for Products. If
        your access to the Website is terminated, Coding Sastra reserves the
        right to exercise whatever means it deems necessary to prevent
        unauthorized access of the Website. This Agreement will survive
        indefinitely unless and until Coding Sastra chooses, in its sole
        discretion and without advance to you, to terminate it. <br />
        <br />
        Domestic Use. Coding Sastra makes no representation that the Website or
        Products are appropriate or available for use in locations outside
        India. Users who access the Website from outside India do so at their
        own risk and initiative and must bear all responsibility for compliance
        with any applicable local laws. Assignment. You may not assign your
        rights and obligations under this Agreement to anyone. Coding Sastra may
        assign its rights and obligations under this Agreement in its sole
        discretion and without advance notice to you.
        <br />
        <br /> BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU
        AGREE
        <br />
        <br /> TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </CareerSectionLead>
    </Container>
    <Footer dark />
  </Layout>
)

export const query = graphql`
  query termsMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
